<template>
  <div>
    <div class="AsideMenu">
      <el-menu
        :default-active="active"
        class="el-menu-vertical-demo"
        @select="handleSelect"
      >
        <!-- <el-menu-item index="1">
          <i class="el-icon-menu"></i>
          <span slot="title">学术简讯</span>
        </el-menu-item> -->
        <el-menu-item index="6">
          <i class="el-icon-money"></i>
          <span slot="title">机构概况</span>
        </el-menu-item>
        <el-menu-item index="7">
            <i class="el-icon-tickets"></i>
            <span slot="title">会议新闻</span>
          </el-menu-item>
        <el-menu-item index="5">
          <i class="el-icon-s-order"></i>
          <span slot="title">质控调研</span>
        </el-menu-item>
        
        <el-menu-item index="2">
          <i class="el-icon-c-scale-to-original"></i>
          <span slot="title">质控指标</span>
        </el-menu-item>

        <!-- <el-menu-item index="8">
          <i class="el-icon-s-custom"></i>
          <span slot="title">学习与讨论</span>
        </el-menu-item> -->
        
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "AsideMenu",
  data() {
    return {
      loginInfo: localStorage.RightExamLoginInfo ? JSON.parse(localStorage.RightExamLoginInfo) : null,
    };
  },
  computed: {
    active: function () {
      const path = this.$route.path;
      if (path.includes("allCase")) {
        return "1";
      } else if (path.includes("QCGF")) {
        return "2";
      } else if(path.includes("appointSetting")){
        return "3";
      }else if(path.includes("appointSetting")){
        return "4";
      }else if(path.includes("allOrder")){
        return "5";
      }else if(path.includes("allExpense")){
        return "6";
      }else if(path.includes("allExams")){
        return '7'
      }else if(path.includes("LearnAndDiscussion")){
        return '8'
      }else if(path.includes("daterule")){
        return '9'
      }else{
        return '0'
      }
    },
    roles: function () {
      if (this.accountType == 4) {
        return [];
      }
      if (this.loginInfo) {
        return this.loginInfo.roles;
      } else {
        return [];
      }
    },
    accountType: function () {
      // console.log(this.loginInfo);
      if (this.loginInfo) {
        return this.loginInfo.accounttype ? this.loginInfo.accounttype: -1;
      } else {
        return -1;
      }
    },
    caseIsShow: function () {
      if (this.accountType == 4) {
        return true;
      }
      return this.roles.includes("案件浏览");
    },
    accountIsShow: function () {
      if (this.accountType == 4) {
        return true;
      }
      return this.roles.includes("账号查看");
    },
    roleIsShow: function () {
      if (this.accountType == 4) {
        return true;
      }
      return this.roles.includes("角色查看");
    },
    fourIsShow:function () {
       if (this.accountType == 4) {
        return true;
      }
      return this.roles.includes("更新协议");
    }
  },
  methods: {
    handleSelect(key) {
      console.log(key);
      if (key == 2) {
        this.$router.push('/QCGF')
        return
      } else if (key == 8) {
        this.$router.push('/LearnAndDiscussion')
        return
      } else if (key == 7) {
        this.$router.push('/HYXW')
        return
      } else if (key == 6) {
        this.$router.push('/JGGK')
        return
      }
      alert('开发中..')
      // if (key == 1) {
      //   this.$router.push("/allCase");
      // } else if (key == 3) {
      //   this.$router.push("/appointSetting");
      // } else if (key == 2) {
      //   this.$router.push("/accounts");
      // } else if (key == 4) {
      //   alert('开发中..')
      // } else if (key == 5){
      //   this.$router.push('/allOrder')
      // } else if (key ==6){
      //   this.$router.push('/allExpense')
      // }else if (key ==7){
      //   this.$router.push('/allExams')
      // }else if (key == 8) {
      //   this.$router.push('/allLeaders')
      // }else if (key == 9) {
      //   this.$router.push('/daterule')
      // }
    },
  },
  created(){
    console.log(this.accountType);
  }
};
</script>

<style scoped>
.AsideMenu {
  width: 100%;
  /* height: calc(100vh - 140px); */
  /* background-color: coral; */
}
.el-menu-vertical-demo {
  height: 100%;
}
</style>