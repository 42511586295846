<template>
  <div>
    <div class="headerBox">
      <div class="logoBox">
        <!-- <img class="logo" src="@/assets/an-logo.png" alt="" /> -->
        <span class="logoTitle">放射影像专业质控中心</span>
      </div>
      <div class="exitBox" v-if="false">
        <span class="accountText">账号：{{ loginName }}</span>
        <!-- <el-button type="text" class="exitBtn resetBtn" @click="resetPassword">重设密码</el-button> -->
        <el-button class="exitBtn" type="text" @click="handleClick"
          >退出</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderNav",
  data() {
    return {
      loginName: localStorage.QCLoginName || "",
    };
  },
  methods: {
    handleClick() {
       this.$confirm("您确定要退出当前账号，回到登录界面?", "退出登录", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          // 确定
          this.$router.replace("/login");
        })
        .catch(() => {
          // 取消
        });
      
    },
    resetPassword(){
      this.$router.push('/resetPassword')
    }
  },
};
</script>

<style scoped>
.headerBox {
  height: 80px;
  width: 100%;
  background: url("../../assets/top_bg.png") no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}
.logoBox {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  margin-left: 40px;
}
.logo {
  /* width: 40px; */
  height: 60px;
  margin-right: 10px;
}
.logoTitle {
  font-size: 25px;
  color: #fff;
  letter-spacing: 3px;
}
.exitBox {
  margin-right: 50px;
}
.exitBtn {
  margin-left: 10px;
  color: #fff;
}
.exitBtn:hover {
  color: antiquewhite;
}
.accountText {
  color: #fff;
}
</style>