<template>
  <div>
    <el-container>
      <el-header>
        <HeaderNav />
      </el-header>
      <el-container class="real_body">
        <el-aside width="200px">
          <AsideMenu />
        </el-aside>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import HeaderNav from "@/components/headerNav";
import AsideMenu from "@/components/asideMenu";

export default {
  name: "Home",
  components: {
    HeaderNav,
    AsideMenu,
  },
};
</script>

<style scoped>
.el-header {
  padding: 0;
  height: 80px !important;
}

.el-container {
  height: 100%;
}

.real_body {
  height: calc(100% - 90px);
}

.el-main {
  background-color: #f5f5f5;
}
</style>